<template>
  <div class="mb-10">
    <h1 class="text-2xl font-bold mb-5">Credit Card Repayments</h1>
    <template v-if="loading">
      <loader />
    </template>
    <template v-else>
      <div
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-5 mb-10"
        style="margin-top: 1px; margin-left: 1px"
      >
        <div
          class="col-span-1 p-6 border"
          style="margin-left: -1px; margin-top: -1px;"
        >
          <div class="text-sm font-thin text-gray-900 mb-3">
            Total Users
          </div>
          <div class="text-sm font-bold">
            {{ summaryData('totalUsers') }}
          </div>
        </div>
        <div
          class="col-span-1 p-6 border"
          style="margin-left: -1px; margin-top: -1px;"
        >
          <div class="text-sm font-thin text-gray-900 mb-3">
            Total Due
          </div>
          <div class="text-sm font-bold">
            ₦ {{ summaryData('totalDue') | currency }}
          </div>
        </div>
        <div
          class="col-span-1 p-6 border"
          style="margin-left: -1px; margin-top: -1px;"
        >
          <div class="text-sm font-thin text-gray-900 mb-3">
            Total Disbursed
          </div>
          <div class="text-sm font-bold">
            ₦ {{ summaryData('totalDisbursed') | currency }}
          </div>
        </div>
        <div
          class="col-span-1 p-6 border"
          style="margin-left: -1px; margin-top: -1px;"
        >
          <div class="text-sm font-thin text-gray-900 mb-3">
            Total Defaulting
          </div>
          <div class="text-sm font-bold">
            ₦ {{ summaryData('totalDefaulting') | currency }}
          </div>
        </div>
        <div
          class="col-span-1 p-6 border"
          style="margin-left: -1px; margin-top: -1px;"
        >
          <div class="text-sm font-thin text-gray-900 mb-3">
            Total Open
          </div>
          <div class="text-sm font-bold">
            ₦ {{ summaryData('totalOpen') | currency }}
          </div>
        </div>
      </div>
    </template>

    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :index="true"
        :columns="creditAccounts.columns"
        :loading="creditAccounts.loading"
        :exportable="true"
        :actions="actions"
        :url="`${this.$baseurl}/admin/personal/cards/credit-card-repayments`"
        :ajax="true"
        :fillable="true"
        :onClick="click"
        :ajaxPagination="true"
        :filters="creditAccounts.filters"
        :query="creditAccounts.query"
        :pageDetails="true"
        rangeable="true"
        ref="table"
      >
        <template #header>
          <div class="pt-4">
            <search-form
              placeholder="Search users names, emails, phone numbers, bvn, etc."
              class="mb-2"
              v-model="creditAccounts.query"
              @submit="reloadTable"
            />
            <div class="flex justify-between">
              <div class="flex items-start flex-wrap xl:flex-no-wrap">
                <div class="flex flex-wrap items-center w-full">
                  <span class="processor-filter-title">Filters:</span>
                  <template v-for="(filter, index) in creditAccounts.filters">
                    <span
                      v-html="filter.title"
                      :key="index"
                      class="processor-filter-item"
                      :class="{ active: currentFilter === filter }"
                      @click.prevent="toggleFilter(filter)"
                    />
                  </template>
                </div>
              </div>
              <div class="flex flex-wrap items-center">
                <button
                  class="btn btn-purple-outline btn-sm mb-2 mr-2"
                  @click.prevent="$refs.table.exportAll()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export All Results</span>
                </button>
                <button
                  class="btn btn-blue-outline btn-sm mb-2"
                  @click.prevent="$refs.table.exportData()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export Filtered Results</span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </datatable>
    </div>

    <modal5
      className="w-11/12 md:w-443px lg:w-443px pt-10 pb-5 rounded"
      ref="balanceClear"
      @close="track = 'clearBalance'"
    >
      <template v-if="track == 'clearBalance'">
        <h4
          class="text-xl xl:text-2xl font-bold md:-ml-4 xl:-ml-4 mb-16 text-left text-gray-700"
        >
          Clear Balance
        </h4>

        <div
          class="w-320px md:w-443px lg:w-443px xl:w-443px h-16 bg-ash-600 -mx-6 -mt-12 md:-mx-16 xl:-mx-16"
        >
          <div class="flex justify-between px-6 lg:px-12 pt-6">
            <p
              class="text-14 font-extrabold text-gray-700 leading-6 text-right"
            >
              Available Credit
            </p>
            <p class="text-14 font-extrabold text-gray-700 text-left">
              {{ formatAmount(creditBalance) }}
            </p>
          </div>
        </div>

        <div class="w-320px md:w-400px xl:w-443px md:-mx-10 lg:-mx-16">
          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Carry Over Balance
            </p>
            <p class="text-12 font-normal text-red-500 text-left">
              {{ carryOverBalance }}
            </p>
          </div>
          
          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Opening Balance
            </p>
            <p class="text-12 font-normal text-red-500 text-left">
              {{ openingBalance }}
            </p>
          </div>

          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Previous payment
            </p>
            <p class="text-12 font-normal text-blue-500 text-left">
              {{ previousPayment }}
            </p>
          </div>
          
          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Interest on Amount Spent
            </p>
            <p class="text-12 font-normal text-red-500 text-left">
              {{ interest }}
            </p>
          </div>

          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Delayed Payment Charge
            </p>
            <p class="text-12 font-normal text-gray-700 text-left">
              {{ defaultCharge }}
            </p>
          </div>

          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Deferred Plan Charge
            </p>
            <p class="text-12 font-normal text-gray-700 text-left">
              {{ deferredPlan }}
            </p>
          </div>

          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Processing Fee
            </p>
            <p class="text-12 font-normal text-gray-700 text-left">
              {{ formatAmount(processingFee) }}
            </p>
          </div>

          <div class="flex justify-between lg:px-12 pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Amount Spent
            </p>
            <p class="text-12 font-normal text-gray-700 text-left">
              {{ amountSpent }}
            </p>
          </div>

          <div class="lg:px-12">
            <svg
              width="363"
              height="1"
              class="mt-6"
              viewBox="0 0 363 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                opacity="0.06"
                x1="-4.37114e-08"
                y1="0.5"
                x2="363"
                y2="0.499968"
                stroke="black"
                stroke-dasharray="5 5"
              />
            </svg>
          </div>

          <div class="flex justify-between lg:px-12 pt-4">
            <p
              class="text-12 font-semibold mt-1 text-gray-700 leading-6 text-right"
            >
              Total due
            </p>
            <p class="text-20 font-bold text-blue-500 text-left">
              {{ formatAmount(total) }}
            </p>
          </div>
          <div class="flex justify-center mt-12 mb-6">
            <div class="flex flex-row">
              <button
                class="w-full px-6 md:px-10 xl:px-10 mr-3 py-4 rounded font-normal btn border border-ash-500 bg-transparent"
                @click.prevent="downloadStatement"
                :disabled="downloading"
              >
                <sm-loader v-if="downloading" />
                <template v-else>
                  <div class="text-indigo-200 inline-flex">
                    <svg
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 8V10.6667C13 11.0203 12.8595 11.3594 12.6095 11.6095C12.3594 11.8595 12.0203 12 11.6667 12H2.33333C1.97971 12 1.64057 11.8595 1.39052 11.6095C1.14048 11.3594 1 11.0203 1 10.6667V8M3.66667 4.66667L7 8M7 8L10.3333 4.66667M7 8V1"
                        stroke="#30469F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      /></svg
                    ><span class="ml-2">Statement</span>
                  </div>
                </template>
              </button>

              <button
                class="w-full btn btn-blue rounded bg-blue-500 py-1 md:py-4 xl:py-4 px-8 md:px-14 xl:px-14 font-normal text-white lg:ml-1"
                @click.prevent="changeTrack"
                :disabled="outstandingAmount <= 0"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </template>

      <template v-if="track == 'repaymentCard'">
        <div>
          <h4 class="text-2xl font-bold mb-16">
            Confirm Amount
          </h4>
          <template v-if="getFormError(chargeForm)">
            <div class="alert alert-red-soft -mt-6 md:-mx-4 mb-6">
              <span class="alert-icon">!</span>
              <span class="text-xs font-normal">{{
                getFormError(chargeForm)
              }}</span>
            </div>
          </template>

          <template v-if="selectedRepayment">
            <p class="text-gray-500">Name</p>
            <div class="rounded p-2">
              <div class="text-xl font-bold">
                {{ selectedRepayment ? selectedRepayment.name : '' }}
                {{ selectedRepayment ? selectedRepayment.last_name : '' }}
              </div>
            </div>

            <p class="text-gray-500">Amount</p>
            <div class="bg-blue-200 rounded p-2 text-center mb-10 h-12">
              <div class="text-xl font-bold">
                ₦{{ total | currency }}
              </div>
            </div>
          </template>

          <div class="mt-4">
            <p class="mb-2 text-left text-gray-500">
              Percentage Charge
            </p>
            <template>
              <form-group
                type="number"
                name="percentage"
                :form="chargeForm"
                v-model="chargeForm.data.percentage.value"
                :autofocus="true"
              />
            </template>
          </div>

          <p class="text-gray-500">Amount to charge</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10 h-12">
            <div class="text-xl font-bold">
              ₦{{ amountToCharge | currency }}
            </div>
          </div>

          <div class="flex justify-center mt-12 mb-6">
            <div class="flex flex-row">
              <button
                class="button px-6 md:px-10 xl:px-10 mr-3 py-4 rounded font-normal btn border border-ash-500 bg-transparent"
                @click.prevent="changeTrack"
              >
                <template>
                  <div class="text-indigo-200 inline-flex">
                    <span class="ml-2">Back</span>
                  </div>
                </template>
              </button>
              <button
                class="btn btn-blue rounded bg-blue-500 py-1 md:py-4 xl:py-4 px-8 md:px-10 xl:px-10 font-normal text-white lg:ml-1"
                @click.prevent="proceedToRepayment"
                :disabled="outstandingAmount <= 0"
              >
                {{ clearing ? 'Loading' : 'Pay' }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </modal5>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="clearStatementModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-16">
        Clear Statement
      </h4>

      <div>
        <template v-if="getFormError(repyamentError)">
          <div class="alert alert-red-soft -mt-6 mb-10">
            <span class="alert-icon">!</span>
            <span class="text-xs font-normal">{{
              getFormError(repyamentError)
            }}</span>
          </div>
        </template>
        <template v-if="selectedRepayment">
          <p class="text-gray-500">Name</p>
          <div class="rounded p-2">
            <div class="text-xl font-bold" v-if="selectedRepayment">
              {{ selectedRepayment.name }}
              {{ selectedRepayment.last_name }}
            </div>
          </div>

          <p class="text-gray-500">Amount</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10">
            <div class="text-xl font-bold">
              ₦{{ outstandingAmount | currency }}
            </div>
          </div>
        </template>

        <div class="mb-6 ">
          <div class="text-sm text-blue-400 mb-6">
            Uploaded Receipts ({{ selectedStatementReceiptsCount }})
          </div>

          <template v-if="selectedStatementReceiptsCount">
            <div class="overflow-y-scroll"            
              :class="{
                'h-48': selectedStatementReceiptsCount > 2
              }"
            >
              <template
                v-for="receipt in selectedStatementReceipts"
                class="h-48 overflow-y-scroll"
              >
                <div :key="receipt.id" class="mb-3">
                  <button
                    type="button"
                    class="px-5 py-3 rounded-sm text-sm inline-block bg-blue-500 hover:bg-blue-600 text-white mb-1"
                    @click="viewReceipt(receipt.id)"
                  >
                    View Receipt
                  </button>
                  <div class="text-sm text-blue-400 font-light">
                    Uploaded at
                    <b class="text-blue-600">{{
                      receipt.created_at | dateFormat('D M dd, Y h:ia')
                    }}</b>
                  </div>
                </div>
              </template>
            </div>
          </template>

          <template v-else>
            <div class="alert alert-gray-soft mb-10">
              <span class="alert-icon">!</span>
              <span>Receipt Not Uploaded.</span>
            </div>
          </template>
        </div>

        <div class="mt-4">
          <p class="mb-2 text-left text-gray-500">
           Amount Paid
          </p>
          <template>
            <form-group
              type="text"
              name="amountPaid"
              :form="chargeNowForm"
              v-model="chargeNowForm.data.amountPaid.value"
              :autofocus="true"
            />
          </template>
           <div v-if="amountPaidError" class="text-red-500 -mt-6">
            <span class="text-xs font-normal">
          {{amountPaidError}}</span>
          </div>
        </div>

        <p class="text-gray-500">Percentage to charge</p>
        <div class="bg-blue-200 rounded p-2 text-center mb-10 h-12">
          <div class="text-xl font-bold">{{ percentageToCharge }}%</div>
        </div>

        <div class="text-center">
          <button
            @click.prevent="clearStatement"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="outstandingAmount <= 0 || clearing"
          >
            <template v-if="clearing">Charging...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-3/5 xl:w-4/10 pt-10"
      ref="filterModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-16">
        Filter
      </h4>

      <div>
        <template v-if="getFormError(repyamentError)">
          <div class="alert alert-red-soft -mt-6 mb-10">
            <span class="alert-icon">!</span>
            <span class="text-xs font-normal">{{
              getFormError(repyamentError)
            }}</span>
          </div>
        </template>

        <div class="">
          <div class="flex justify-between">
            <p class="self-center">Date</p>
            <div class="flex flex-wrap md:flex-no-wrap mt-6">
              <div class="filter-date md:mr-6">
                <form-group
                  type="date"
                  rightIcon="calendar-outline"
                  name="date_of_birth"
                  className="filter-date"
                >
                  From
                </form-group>
              </div>

              <div class="filter-date">
                <form-group
                  type="date"
                  rightIcon="calendar-outline"
                  name="date_of_birth"
                  className="filter-date"
                >
                  to
                </form-group>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 xl:grid-cols-4">
            <div class="col-span-1 self-center">
              <p class="self-center">Status</p>
            </div>

            <div class="col-span-3">
              <div class="flex flex-wrap md:flex-no-wrap w-full">
                <div class="flex">
                  <checkbox id="disbursed_check" class="mr-3" />
                  <label for="disbursed_check" class="text-xs self-center"
                    >Disbursed</label
                  >
                </div>
                <div class="flex mx-6">
                  <checkbox id="due_check" class="mr-3" />
                  <label for="due_check" class="text-xs self-center">Due</label>
                </div>
                <div class="flex">
                  <checkbox id="default_check" class="mr-3" />
                  <label for="default_check" class="text-xs self-center"
                    >Defualting</label
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 xl:grid-cols-4">
            <div class="col-span-1 self-center">
              <p class="self-center">Location</p>
            </div>

            <div class="col-span-3 my-10">
              <div class="flex flex-wrap md:flex-no-wrap w-full">
                <div class="flex">
                  <checkbox id="lagos_check" class="mr-3" />
                  <label for="lagos_check" class="text-xs self-center"
                    >Lagos</label
                  >
                </div>
                <div class="flex mx-6">
                  <checkbox id="ilorin_check" class="mr-3" />
                  <label for="ilorin_check" class="text-xs self-center"
                    >Ilorin</label
                  >
                </div>
                <div class="flex">
                  <checkbox id="abuja_check" class="mr-3" />
                  <label for="abuja_check" class="text-xs self-center"
                    >Abuja</label
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 xl:grid-cols-4">
            <div class="col-span-1 self-center">
              <p class="self-center">Approval Status</p>
            </div>

            <div class="col-span-3">
              <div class="flex flex-wrap md:flex-no-wrap w-full">
                <div class="flex">
                  <checkbox id="standard" class="mr-3" />
                  <label for="standard" class="text-xs self-center"
                    >Standard</label
                  >
                </div>
                <div class="flex mx-6">
                  <checkbox id="express" class="mr-3" />
                  <label for="express" class="text-xs self-center"
                    >Express</label
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="w-full text-center mt-10">
            <button
              class="btn btn-blue"
              @click.prevent="openFilterModal"
              :disabled="creditAccounts.loading"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-3/5 xl:w-4/10 pt-10"
      ref="commentModal"
      @close="removeError"
    >
      <h4 v-if="selectedAccount" class="text-sm font-bold mb-2">
        {{ selectedAccount.name }} {{ selectedAccount.last_name }} Account
        Comment
      </h4>
      <router-link
        v-if="selectedAccount"
        :to="{ name: 'staff-view', params: { staffId: selectedAccount.id } }"
        class="btn btn-blue mr-2 mb-6"
      >
        <ion-icon name="body-outline" class="text-xl"></ion-icon>
        <span class="ml-2">View Profile</span>
      </router-link>
      <div class="flex-grow mb-4">
        <form>
          <form-group
            type="textarea"
            name="comment"
            :form="creditCardComment"
            v-model="creditCardComment.data.body.value"
          >
            Comments.
          </form-group>

          <button
            type="button"
            class="btn btn-sm btn-blue ml-auto"
            :disabled="creditCardComment.loading"
            @click.prevent="makeComment"
          >
            <span v-if="creditCardComment.loading">Saving...</span>
            <span v-else>Save</span>
          </button>
        </form>
      </div>
      <div class="max-h-30 overflow-y-scroll">
        <template v-for="(comment, i) in currentcreditCardComments">
          <div :key="i" class="border border-gray-500 mb-4">
            <div class="border-b border-gray-500 px-4 py-1 text-sm font-thin">
              {{ comment.admin.name }} {{ comment.admin.last_name }} commented
              on {{ new Date(comment.created_at).format('dd M, Y') }}
            </div>
            <div class="py-4 px-4 font-normal">{{ comment.body }}</div>
          </div>
        </template>
      </div>
    </modal>

    <modal
      className="w-11/12 md:w-3/5 xl:w-443px rounded text-center pt-24"
      ref="repaymentSuccessModal"
    >
      <img
        :src="
          repaymentStatus
            ? assets.icons.lg.checkmark
            : assets.icons.lg.exclamation
        "
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        {{ repaymentStatus ? 'Success!' : 'Alert!' }}
      </div>
      <div class="text-12 mb-10" v-html="paymentMessage"></div>
    </modal>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      currentFilter: null,
      repaymentStat: null,
      repaymentStatus: false,
      downloading: false,
      track: 'clearBalance',
      searchQuery: '',
      company: null,
      loading: false,
      repayments: [],
      clearing: false,
      repyamentError: {},
      paymentMessage: 'Your Repayment was successful.',
      cardId: 0,
      svg: {
        visa: require('@/assets/visa-dark.svg'),
        master: require('@/assets/mastercard.svg'),
        check: require('@/assets/blue-check.svg')
      },
      actions: [
        {
          text: 'charge now',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openConfirmModal,
          disabled: user => {
            return user.personal_account?.latest_statement?.condition === 'paid' ||
            user.personal_account?.ongoing_charge 
            || user.personal_account?.latest_statement?.receipts?.length > 0
          }
            
        },
        {
          text: 'clear statement',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openclearStatementModal,
          disabled: user =>
            user.personal_account?.latest_statement?.condition === 'paid' ||
            user.personal_account?.ongoing_charge
        }
      ],
      selectedAmount: null,
      chargeError: null,
      selectedRepayment: null,
      selectedStatement: null,
      repaymentCards: this.$options.resource([]),
      creditAccounts: this.$options.resource([], {
        query: '',
        columns: [
          {
            name: 'user',
            th: 'User',
            // render: card => card.personal_account?.user?.name
            render: account => {
              if (!account) {
                return 'N/A';
              }
              return `${account?.name} ${account?.last_name}`;
            }
          },
          {
            name: 'type',
            th: 'Card Type',
            render: account => {
              if (!account?.personal_account?.type) {
                return 'N/A';
              }
              return `${account?.personal_account?.type}`;
            }
          },
          {
            name: 'available_credit',
            th: 'Available Credit',
            render: account => {
              if (account?.personal_account) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  account?.personal_account?.available_credit
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            }
          },
          {
            name: 'credit_limit',
            th: 'Credit Limit',
            render: account => {
              if (account?.personal_account) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  account?.personal_account?.credit_card_limit
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            }
          },
          {
            name: 'available_balance',
            th: 'Available Balance',
            render: account => {
              if (account?.personal_account) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  account?.personal_account?.available_balance
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            }
          },
          {
            name: 'location',
            th: 'Location',
            render: account => {
              return account?.profile?.state || 'N/A';
            }
          },
          {
            name: 'status',
            th: 'Ongoing Charge',
            render: account => {
              if (account?.personal_account?.ongoing_charge) {
                return '<span class="text-red-500">Charging</span>';
              }
              return '<span class="text-green-500">Good</span>';
            }
          },
          {
            name: 'billing_date',
            th: 'Billing Date',
            render: account => {
              if (account) {
                return `<span class="text-blue-500">
                  ${account?.profile?.billing_date}
                </span>`;
              }
              return '<span class="text-gray-500">N/A</span>';
            }
          },
          {
            name: 'salary_day',
            th: 'Due Date',
            render: account => {
              if (account) {
                return `<span class="text-blue-500">
                  ${account?.profile?.salary_day}
                </span>`;
              }
              return '<span class="text-gray-500">N/A</span>';
            }
          },
          {
            name: 'receipts',
            th: 'Receipts',
            render: account => {
              if (account?.personal_account?.latest_statement?.receipts?.length) {
                return `<span class="text-red-500 font-bold">
                  ${account?.personal_account?.latest_statement?.receipts?.length}
                </span>`;
              }
              return '<span class="text-gray-500">0</span>';
            }
          },
          {
            name: 'status',
            th: 'Status',
            render: account => {
              switch (account?.personal_account.cycle_status) {
                case 'ongoing':
                  return '<span class="text-green-500">Ongoing</span>';
                case 'due':
                  return '<span class="text-yellow-500">Due</span>';
                case 'default':
                  return '<span class="text-red-500">Default</span>';

                default:
                  return '<span class="text-green-500">Ongoing</span>';
              }
            }
          }
        ],
        filters: [
          { title: 'ongoing', name: 'ongoing' },
          { title: 'defaulting', name: 'default' },
          { title: 'due', name: 'due' }
        ]
      }),
      creditCardComment: this.$options.basicForm(['body']),
      currentcreditCardComments: [],
      commentsLoading: false,
      selectedAccount: null,
      chargeNowForm: this.$options.basicForm([
        // {
        //   name: 'percentage',
        //   rules: 'required|number|max:100|min:1'
        // },
        { name: 'amountPaid', rules: 'required|float'}
      ]),
      chargeForm: this.$options.basicForm([
        {
          name: 'percentage',
          rules: 'required|number|max:100|min:1'
        }
      ]),
      selectedStatementReceipts: [],
      amountPaidError: ''
    };
  },
  computed: {
    outstandingAmount() {
      return this.selectedStatement?.summary?.net_outstanding || 0;
    },
    creditLimit() {
      return this.selectedRepayment?.personal_account?.credit_card_limit || 0;
    },
    creditBalance() {
      return this.selectedRepayment?.personal_account?.available_credit;
    },
    creditUsed() {
      return Math.abs(
        this.selectedRepayment?.personal_account?.available_balance || 0
      );
    },
    amountSpent() {
      const amount = parseFloat(this.selectedStatement?.statement?.agg_balance || 0)  + parseFloat(this.selectedStatement?.statement?.carry_over_balance || 0)
      return this.formatAmount(
        amount
      );
    },
    interest() {
      return this.formatAmount(this.selectedStatement?.statement?.interest);
    },
    deferredPlan() {
      return this.formatAmount(
        this.selectedStatement?.statement?.deferred_plan_amount
      );
    },
    defaultCharge() {
      return this.formatAmount(
        this.selectedStatement?.statement?.fees_and_charges
      );
    },
    openingBalance() {
      return this.formatAmount(
        this.selectedStatement?.statement?.opening_balance
      );
    },
    carryOverBalance() {
      return this.formatAmount(
        this.selectedStatement?.statement?.carry_over_balance
      );
    },
    previousPayment() {
      return this.formatAmount(
        this.selectedStatement?.summary?.payments
      );
    },
    paystackFee(amount = 0) {
      let fee = this.selectedStatement?.statement?.total_outstanding;
      if (amount > 0) {
        fee = amount;
      }

      const percentage = parseFloat(1.78 / 100);
      if (percentage <= 0) {
        return 0;
      }

      let totalFee = fee / (1 - percentage);

      if (totalFee >= 2500) {
        totalFee += 100;
      }

      let paystackChargeFee = totalFee - fee;

      if (paystackChargeFee > 2000) {
        paystackChargeFee = 2000;
      }

      return parseFloat(paystackChargeFee);
    },
    processingFee() {
      return (this.selectedStatement?.summary?.processing_fee || 0);
    },
    totalBalance() {
      return parseFloat(this.selectedStatement?.summary?.net_outstanding);
    },
    total() {
      const summary = this.selectedStatement?.summary;
      return this.totalBalance + parseFloat(summary?.net_processing_fee) - parseFloat(this.processingFee);
    },
    amountToCharge() {
      const percentage = this.chargeForm.data.percentage.value || 0;
      const amountToPay =
        (percentage / 100) * this.outstandingAmount;
      const checkAmount = isFinite(amountToPay);
      if (!checkAmount || !percentage || percentage <= 0) {
        return 0;
      }
      return amountToPay;
    },
    selectedStatementReceiptsCount() {
      return this.selectedStatementReceipts?.length || 0;
    },
    percentageToCharge() {
      const value = this.chargeNowForm.data.amountPaid.value;
      if (isNaN(parseFloat(value))) {
        this.setAmountError(true)
        return 0;
      }
      this.setAmountError(false)
      const amountPaid = this.chargeNowForm.data.amountPaid.value || 0;
      const percentageToCharge = (amountPaid / this.outstandingAmount) * 100;
      const checkAmount = isFinite(percentageToCharge);

      if (!checkAmount || !percentageToCharge || amountPaid <= 0) {
        return 0;
      }
      return parseFloat(percentageToCharge.toFixed(2));
    }
  },
  beforeMount() {
    this.getCreditCardRepayments();
    this.getFilteredData();
  },
  mounted() {
    this.setAmountError(false)
  },
  methods: {
    setAmountError(error) {
      if(!error) {
        this.amountPaidError = ''
        return;
      }
        this.amountPaidError = "Please provide the correct amount";
    },
    openConfirmModal(account) {
      this.selectedRepayment = account;
      this.getAccountStatement();
      this.$refs.balanceClear.open();
    },
    openClearBalanceModal() {
      this.$refs.clearanceTypeModal.close();
      this.$refs.balanceClear.open();
    },
    openclearStatementModal(account) {
      this.selectedRepayment = account;
      this.selectedStatementReceipts =
        account?.personal_account?.latest_statement?.receipts;
      this.getAccountStatement();
      this.$refs.clearStatementModal.open();
    },
    removeError() {
      this.chargeError = '';
      this.chargeForm.data.percentage.value = 0;
    },
    changeClearanceType(type) {
      this.clearanceType = type;
    },
    changeTrack() {
      this.track =
        this.track === "clearBalance" ? "repaymentCard" : "clearBalance";
    },
    repaymentCardType(cardType) {
      return cardType.includes('visa')
        ? 'visa'
        : cardType.includes('verve')
        ? 'verve'
        : 'master';
    },
    setCardId(index) {
      this.cardId = index;
    },
    getRepaymentCardDetails() {
      return this.repaymentCards.data[this.cardId];
    },
    openFilterModal() {
      this.$refs.filterModal.open();
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
        this.getFilteredData();
      } else {
        this.currentFilter = filter;
        this.getFilteredData(filter);
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    summaryData(key, default_value = 0) {
      const data = this.repaymentStat?.[key];
      return data ? data : default_value;
    },
    openCommentModal(user) {
      if (typeof user === 'string') {
        return;
      }
      this.selectedAccount = user;
      this.getCreditCardComments(user.personal_account.id);
      this.$refs.commentModal.open();
    },
    click(defaultUser) {
      this.openCommentModal(defaultUser);
    },
    paystackFeeCalculator(amount = 0) {
      let fee = this.selectedStatement?.statement?.total_outstanding;
      if (amount > 0) {
        fee = amount;
      }

      const percentage = parseFloat(1.78 / 100);
      if (percentage <= 0) {
        return 0;
      }

      let totalFee = fee / (1 - percentage);

      if (totalFee >= 2500) {
        totalFee += 100;
      }

      let paystackChargeFee = totalFee - fee;

      if (paystackChargeFee > 2000) {
        paystackChargeFee = 2000;
      }

      return parseFloat(paystackChargeFee);
    },
    async getFilteredData(filter) {
      let status = filter?.name;
      if (!status) {
        status = 'default';
      }
      this.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/personal/cards/credit-card-repayments/filter/summary/${status}`,
        headers: this.headers,
        success: response => {
          this.repaymentStat = response.data.data;
        },
        error: error => {
          console.log('err', error);
        }
      });

      this.loading = false;
    },
    async clearTotalBalance() {
      this.clearing = true;
      this.$refs.clearButton.$el.click();
      setTimeout(() => {
        this.clearing = false;
      }, 800);
    },
    async completeClearing(data) {
      var { reference } = data;

      this.clearing = true;

      await this.$post({
        url: `${this.$baseurl}/repayment/personal`,
        data: {
          payment_method: 'paystack',
          reference
        },
        headers: this.headers,
        success: () => {
          this.addRepaymentCard(reference);
        },
        error: error => {
          this.repyamentError.error = error;
        }
      });

      this.clearing = false;
    },
    async getCreditCardRepayments() {
      this.creditAccounts.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/personal/cards/credit-card-repayments`,
        headers: this.headers,
        success: response => {
          this.creditAccounts.data = response.data.creditAccounts;
        }
      });
      this.creditAccounts.loading = false;
    },
    async getAccountStatement() {
      this.clearing = true;
      await this.sendRequest(
        'admin.cards.personal.statements.getStatementBreakDown',
        this.selectedRepayment?.id,
        {
          success: response => {
            this.selectedStatement = response.data.data;
          },
          error: () => {
            this.$error({
              title: 'Statement Error',
              body: 'Unable to get statement.',
              button: 'Okay'
            });
          }
        }
      );
      this.clearing = false;
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async proceedToRepayment() {
      if (!this.validateForm(this.chargeForm)) {
        return false;
      }
      this.clearing = true;
      await this.sendRequest('admin.cards.personal.chargeUser', {
        data: {
          statement_id: this.selectedStatement?.statement?.id,
          admin_id: this.user.id,
          charge_percentage: this.chargeForm.data.percentage.value
        },
        success: response => {
          this.$refs.balanceClear.close();
          this.$success({
            title: 'Statement Charge Successful',
            body: response?.data?.message ?? 'There was an error.',
            button: 'Okay'
          });
          this.getCreditCardRepayments();
          this.changeTrack();
        },
        error: error => {
          this.$error({
            title: 'Statement Error',
            body: error.response?.data?.message ?? 'There was an error.',
            button: 'Okay'
          });
        }
      });
      this.clearing = false;
    },
    async showRepaymentSuccess(repaymentId) {
      await this.$get({
        url: `${this.$baseurl}/repayment/personal/one/${repaymentId}`,
        headers: this.headers,
        success: res => {
          this.$refs.repaymentSuccessModal.open();
          if (
            res.data.data.status == 'pending' &&
            res.data.data.status != 'decline'
          ) {
            this.paymentMessage =
              '<p style="font-weight: bold">This repayment is still pending. Check again later!</p>';
          } else {
            this.paymentMessage = 'Repayement is successful';
            this.repaymentStatus = true;
          }
        },
        error: error => {
          this.repyamentError.error = error;
        }
      });
    },
    async clearStatement() {
      this.clearing = true;
      await this.sendRequest('admin.cards.personal.clearStatement', {
        data: {
          statement_id: this.selectedStatement?.statement?.id,
          admin_id: this.user.id,
          clearance_percentage: this.percentageToCharge
        },
        success: response => {
          this.$refs.clearStatementModal.close();
          this.$success({
            title: 'Statement Marked Successfully',
            body: response?.data?.message ?? 'There was an error.',
            button: 'Okay'
          });
          this.getCreditCardRepayments();
          this.changeTrack();
        },
        error: error => {
          this.$error({
            title: 'Statement Error',
            body: error.response?.data?.message ?? 'There was an error.',
            button: 'Okay'
          });
        }
      });
      this.clearing = false;
      await this.$root.loadSession();
    },
    async downloadStatement() {
      this.downloading = true;
      axios
        .get(
          `${this.$baseurl}/personal/statement/${this.selectedStatement?.statement?.id}/download`,
          {
            headers: this.headers
          }
        )
        .then(res => {
          window.open(res.data.data.url);
        })
        .catch(err => {
          console.log(err);
        });
      this.downloading = false;
    },
    async makeComment() {
      this.creditCardComment.loading = true;

      await this.$post({
        url: `${this.$baseurl}/admin/personal/cards/credit-card-repayments/comment`,
        data: {
          accountId: this.selectedAccount?.personal_account?.id,
          body: this.creditCardComment.data.body.value
        },
        headers: this.headers,
        success: () => {
          // this.getStatementComments();
          this.creditCardComment = this.resetForm(this.creditCardComment);
          this.successMessage = 'Comment saved successfully.';
          this.getCreditCardComments(
            this.selectedAccount?.personal_account?.id
          );
          this.$refs.successModal.open();
        },
        error: error => {
          this.successMessage = '';
          this.creditCardComment.error = error;
        }
      });
      this.creditCardComment.loading = false;
    },
    async getCreditCardComments(accountId) {
      this.commentsLoading = true;

      await this.$get({
        url: `${this.$baseurl}/admin/personal/cards/credit-card-repayments/comment/${accountId}`,
        headers: this.headers,
        success: response => {
          this.currentcreditCardComments = response.data.comments;
        },
        error: error => {
          this.creditCardComment.error = error;
        }
      });
      this.commentsLoading = false;
      await this.$root.loadSession();
    },

    async viewReceipt(receiptId) {
      await this.sendRequest('admin.cards.personal.viewReceipt', receiptId, {
        success: response => {
          window.open(
            response.data.url,
            '_blank' // <- This is what makes it open in a new window.
          );
        },
        error: () => {
          this.$error({
            title: 'Receipt Error',
            body: 'There was an error.',
            button: 'Okay'
          });
        }
      });
    }
  }
};
</script>

<style lang="sass">
.processor-select-input
  @apply bg-white border border-blue-200 duration-300 font-medium px-6 py-3 relative rounded-sm text-gray-600
  font-size: 13px
  &:hover
    @apply bg-blue-200 border-blue-200 text-blue-900
  &:focus-within
    @apply border-blue-500 text-blue-500
.processor-filter-title
  @apply font-bold mb-2 mr-2 text-sm uppercase w-full
.processor-filter-item
  @apply bg-white border cursor-pointer font-bold mb-2 mr-2 px-3 py-2 rounded-sm text-xs text-gray-600 tracking-wide uppercase
  &:hover
    @apply bg-blue-100 border-blue-500 text-blue-500
  &.active
    @apply bg-blue-500 border-blue-500 text-white
</style>
